// const eva = {
//   primary100: 'hsl(258, 89%, 93%)',
//   primary200: 'hsl(257, 89%, 86%)',
//   primary300: 'hsl(255, 80%, 77%)',
//   primary400: 'hsl(254, 71%, 69%)',
//   primary500: 'hsl(253, 65%, 58%)',
//   primary600: 'hsl(252, 54%, 47%)',
//   primary700: 'hsl(250, 61%, 38%)',
//   primary800: 'hsl(249, 68%, 29%)',
//   primary900: 'hsl(247, 76%, 23%)',
//   success100: 'hsl(127, 77%, 93%)',
//   success200: 'hsl(133, 76%, 87%)',
//   success300: 'hsl(139, 62%, 76%)',
//   success400: 'hsl(145, 48%, 66%)',
//   success500: 'hsl(151, 39%, 52%)',
//   success600: 'hsl(157, 43%, 43%)',
//   success700: 'hsl(162, 51%, 34%)',
//   success800: 'hsl(169, 59%, 26%)',
//   success900: 'hsl(175, 69%, 20%)',
//   info100: 'hsl(192, 100%, 93%)',
//   info200: 'hsl(195, 100%, 86%)',
//   info300: 'hsl(199, 100%, 79%)',
//   info400: 'hsl(202, 100%, 74%)',
//   info500: 'hsl(205, 100%, 65%)',
//   info600: 'hsl(209, 69%, 54%)',
//   info700: 'hsl(212, 65%, 44%)',
//   info800: 'hsl(216, 72%, 34%)',
//   info900: 'hsl(219, 79%, 27%)',
//   warning100: 'hsl(52, 100%, 90%)',
//   warning200: 'hsl(51, 100%, 80%)',
//   warning300: 'hsl(49, 100%, 70%)',
//   warning400: 'hsl(48, 100%, 62%)',
//   warning500: 'hsl(47, 100%, 50%)',
//   warning600: 'hsl(45, 100%, 43%)',
//   warning700: 'hsl(44, 100%, 36%)',
//   warning800: 'hsl(43, 100%, 29%)',
//   warning900: 'hsl(41, 100%, 24%)',
//   danger100: 'hsl(10, 94%, 94%)',
//   danger200: 'hsl(2, 97%, 88%)',
//   danger300: 'hsl(357, 96%, 81%)',
//   danger400: 'hsl(351, 93%, 76%)',
//   danger500: 'hsl(345, 94%, 68%)',
//   danger600: 'hsl(339, 64%, 56%)',
//   danger700: 'hsl(334, 57%, 45%)',
//   danger800: 'hsl(328, 65%, 34%)',
//   danger900: 'hsl(322, 72%, 27%)'
// };

const neutral900 = 'hsl(0, 0%, 15%)';
const neutral800 = 'hsl(0, 0%, 23%)';
const neutral700 = 'hsl(0, 0%, 31%)';
const neutral600 = 'hsl(0, 0%, 56%)';
const neutral500 = 'hsl(0, 0%, 71%)';
const neutral400 = 'hsl(0, 0%, 84%)';
const neutral300 = 'hsl(0, 0%, 91%)';
const neutral200 = 'hsl(0, 0%, 96%)';
const neutral100 = 'hsl(0, 0%, 98%)';

const primary900 = 'hsl(247, 76%, 23%)';
const primary800 = 'hsl(249, 68%, 29%)';
const primary700 = 'hsl(250, 61%, 38%)';
const primary600 = 'hsl(252, 54%, 47%)';
const primary500 = 'hsl(253, 65%, 58%)';
const primary400 = 'hsl(254, 71%, 69%)';
const primary300 = 'hsl(255, 80%, 77%)';
const primary200 = 'hsl(257, 89%, 86%)';
const primary100 = 'hsl(258, 89%, 93%)';

const danger900 = 'hsl(6, 86%, 20%)';
const danger800 = 'hsl(6, 82%, 27%)';
const danger700 = 'hsl(6, 80%, 36%)';
const danger600 = 'hsl(6, 70%, 47%)';
const danger500 = 'hsl(6, 82%, 58%)';
const danger400 = 'hsl(6, 86%, 65%)';
const danger300 = 'hsl(6, 95%, 74%)';
const danger200 = 'hsl(6, 96%, 85%)';
const danger100 = 'hsl(6, 96%, 94%)';

const success900 = 'hsl(175, 69%, 20%)';
const success800 = 'hsl(169, 59%, 26%)';
const success700 = 'hsl(162, 51%, 34%)';
const success600 = 'hsl(157, 43%, 43%)';
const success500 = 'hsl(151, 39%, 52%)';
const success400 = 'hsl(145, 48%, 66%)';
const success300 = 'hsl(139, 62%, 76%)';
const success200 = 'hsl(133, 76%, 87%)';
const success100 = 'hsl(127, 77%, 93%)';
const success50 = 'hsl(127, 77%, 96%)';

// const success900 = 'hsl(151, 46%, 19%)';
// const success800 = 'hsl(151, 44%, 25%)';
// const success700 = 'hsl(151, 43%, 35%)';
// const success600 = 'hsl(151, 41%, 44%)';
// const success500 = 'hsl(151, 39%, 52%)';
// const success400 = 'hsl(151, 42%, 64%)';
// const success300 = 'hsl(151, 43%, 76%)';
// const success200 = 'hsl(151, 45%, 84%)';
// const success100 = 'hsl(151, 50%, 92%)';
// const success50 = 'hsl(151, 50%, 96%)';

const warning = 'hsl(38, 70%, 55%)';

const border = neutral300;
const borderFocused = neutral500;
const bodyText = neutral900;
const primary = primary500;
const danger = danger500;
const success = success500;

export const colors = {
  bodyText,
  border,
  borderFocused,
  /* primary */
  primary,
  primary900,
  primary800,
  primary700,
  primary600,
  primary500,
  primary400,
  primary300,
  primary200,
  primary100,
  /* danger */
  danger,
  danger900,
  danger800,
  danger700,
  danger600,
  danger500,
  danger400,
  danger300,
  danger200,
  danger100,
  /* success */
  success,
  success900,
  success800,
  success700,
  success600,
  success500,
  success400,
  success300,
  success200,
  success100,
  success50,
  /* neutral */
  neutral900,
  neutral800,
  neutral700,
  neutral600,
  neutral500,
  neutral400,
  neutral300,
  neutral200,
  neutral100,
  /* warning */
  warning
};
