import Packages from './Packages';
import Circles from './Circles';

export default function Order({ order, onExpand, isExpanded }) {
  function handleCardClick() {
    if (!isExpanded) {
      if (onExpand) {
        onExpand(order.id);
      }
    }
  }

  function handleShowLessClick() {
    if (onExpand) {
      onExpand(null);
    }
  }

  return (
    <li className={isExpanded ? 'expanded' : null} onClick={handleCardClick}>
      <header>
        <div className="left-side">
          <h3>{order.title}</h3>
          <span className="timestamp">
            {order.created}&nbsp;&middot;&nbsp;{order.direction}
          </span>
        </div>
        <div className="right-side">
          <Circles order={order} />
          <div className="labels">
            <span className="label label-neutral label-tiny">
              {order.price}
            </span>
            <span className="label label-inverse label-tiny">
              {order.weight}
            </span>
          </div>
        </div>
      </header>
      {isExpanded ? (
        <Packages order={order} onShowLessClick={handleShowLessClick} />
      ) : null}
    </li>
  );
}
