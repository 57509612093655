// Base size is 16px (s4)

export const sizesRaw = {
  s1: 4, // base x 0.25
  s2: 8, // base x 0.5
  s3: 12, // base x 0.75
  s4: 16, // base x 1
  s5: 24, // base x 1.5
  s6: 32, // base x 2
  s7: 48, // base x 3
  s8: 64, // base x 4
  s9: 96, // base x 6
  s10: 128, // base x 8
  s11: 192, // base x 12
  s12: 256, // base x 16
  s13: 384, // base x 24
  s14: 512, // base x 32
  s15: 640, // base x 40
  s16: 768, // base x 48
  s17: 960, // base x 60
  s18: 1152, // base x 72
  s19: 1344, // base x 84
  s20: 1536, // base x 96
  controlHeightTiny: 22,
  controlHeightSmall: 32,
  controlHeight: 36,
  controlHeightLarge: 48,
  controlHeightExtraLarge: 54
};

export const sizes = {
  s1: sizesRaw.s1 + 'px',
  s2: sizesRaw.s2 + 'px',
  s3: sizesRaw.s3 + 'px',
  s4: sizesRaw.s4 + 'px',
  s5: sizesRaw.s5 + 'px',
  s6: sizesRaw.s6 + 'px',
  s7: sizesRaw.s7 + 'px',
  s8: sizesRaw.s8 + 'px',
  s9: sizesRaw.s9 + 'px',
  s10: sizesRaw.s10 + 'px',
  s11: sizesRaw.s11 + 'px',
  s12: sizesRaw.s12 + 'px',
  s13: sizesRaw.s13 + 'px',
  s14: sizesRaw.s14 + 'px',
  s15: sizesRaw.s15 + 'px',
  s16: sizesRaw.s16 + 'px',
  s17: sizesRaw.s17 + 'px',
  s18: sizesRaw.s18 + 'px',
  s19: sizesRaw.s19 + 'px',
  s20: sizesRaw.s20 + 'px',
  controlHeightTiny: sizesRaw.controlHeightTiny + 'px',
  controlHeightSmall: sizesRaw.controlHeightSmall + 'px',
  controlHeight: sizesRaw.controlHeight + 'px',
  controlHeightLarge: sizesRaw.controlHeightLarge + 'px',
  controlHeightExtraLarge: sizesRaw.controlHeightExtraLarge + 'px'
};
