import React from 'react';
import styled from 'styled-components';
import { colors, sizes, shadows, radiuses, fontSizes } from './ui';

const Wrapper = styled.div`
  padding: ${sizes.s3} ${sizes.s4};
  box-shadow: ${shadows.s2};
  border-radius: ${radiuses.r3};
  background-color: #fff;
  flex: 1 0 auto;

  section {
    &:not(:last-child) {
      margin-bottom: ${sizes.s6};
    }

    h3 {
      margin: 0px;
      font-size: ${fontSizes.s4};
      margin-bottom: ${sizes.s2};
    }

    p.notice {
      color: ${colors.neutral600};
      font-size: ${fontSizes.s3};

      margin-top: 0px;
      margin-bottom: ${sizes.s3};
    }

    table.hours {
      margin-bottom: ${sizes.s2};

      tbody {
        tr {
          td {
            padding: 0px 0px;
          }

          td:first-child {
            padding-right: ${sizes.s4};
          }
        }
      }
    }
  }
`;

export default function Contacts() {
  return (
    <Wrapper>
      <section>
        <h3>Офис на Бали</h3>
        <table className="hours">
          <tbody>
            <tr>
              <td>Понедельник</td>
              <td>10:00–21:00</td>
            </tr>
            <tr>
              <td>Вторник</td>
              <td>10:00–21:00</td>
            </tr>
            <tr>
              <td>Среда</td>
              <td>10:00–21:00</td>
            </tr>
            <tr>
              <td>Четверг</td>
              <td>10:00–21:00</td>
            </tr>
            <tr>
              <td>Пятница</td>
              <td>10:00–21:00</td>
            </tr>
            <tr>
              <td>Суббота</td>
              <td>10:00–21:00</td>
            </tr>
            <tr>
              <td>Воскресенье</td>
              <td>10:00–21:00</td>
            </tr>
          </tbody>
        </table>
        <a
          href="https://goo.gl/maps/znjhBzffuTyVYM1y7"
          target="_blank"
          rel="noopener"
        >
          Открыть на Google Maps ↗
        </a>
      </section>
      <section>
        <h3>Офис в Москве</h3>
        <table className="hours">
          <tbody>
            <tr>
              <td>Понедельник</td>
              <td>10:00–18:00</td>
            </tr>
            <tr>
              <td>Вторник</td>
              <td>10:00–18:00</td>
            </tr>
            <tr>
              <td>Среда</td>
              <td>10:00–18:00</td>
            </tr>
            <tr>
              <td>Четверг</td>
              <td>10:00–18:00</td>
            </tr>
            <tr>
              <td>Пятница</td>
              <td>10:00–18:00</td>
            </tr>
            <tr>
              <td>Суббота</td>
              <td>10:00–18:00</td>
            </tr>
            <tr>
              <td>Воскресенье</td>
              <td>Выходной</td>
            </tr>
          </tbody>
        </table>
        <p className="notice">* Просьба заранее согласовать время прибытия</p>
        <a
          href="https://goo.gl/maps/hk6apfudRP6JTeE39"
          target="_blank"
          rel="noopener"
        >
          Открыть на Google Maps ↗
        </a>
      </section>
      <section>
        <h3>Сайт компании</h3>
      <a
                href="https://butler.live"
                target="_blank"
                rel="noopener"
              >
                Открыть butler.live ↗
              </a>
      </section>

    </Wrapper>
  );
}
