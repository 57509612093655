import dateFormat from 'dateformat';

const viewModel = {
  shape
};

function shape(data) {
  const { data: { orders = [] } = {} } = data;
  return orders.map(order => {
    return {
      ...order,
      title: order.title,
      created: dateFormat(new Date(order.created), 'dd mmm yyyy'),
      direction: getDirection(order.from),
      price: formatPrice(order.price),
      weight: formatWeight(order.weight || 0),
      circles: getCircles(order),
      packages: order.packages.map(shapePackage)
    };
  });
}

function getCircles(order) {
  return order.packages
    .map(pkg => {
      const isStatusGreen =
        pkg.status === 'Посылка готова к выдаче' ||
        pkg.status === 'Посылка выдана';
      const isPaymentStatusGreen = pkg.paymentStatus === 'Оплачено';
      const isGreen = isStatusGreen && isPaymentStatusGreen;
      return { isGreen };
    })
    .sort((a, b) => a.isGreen - b.isGreen);
}

function shapePackage(pkg) {
  return {
    ...pkg,
    title: pkg.title,
    price: formatPrice(pkg.price),
    weight: formatWeight(pkg.weight),
    statusColor: getStatusColor(pkg.status),
    status: transformStatus(pkg.status),
    paymentStatusColor: getPaymentStatusColor(pkg.paymentStatus),
    paymentStatus: pkg.paymentStatus
  };
}

function formatWeight(value) {
  return value.toLocaleString('ru-RU', { style: 'decimal' }) + '\xa0г';
}

function formatPrice(value) {
  return value.toLocaleString('ru-RU', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });
}

function transformStatus(status) {
  switch (status) {
    case 'Посылка принята от клиента':
      return 'Принято';
    case 'Посылка готова к выдаче':
      return 'Готово к выдаче';
    case 'Посылка выдана':
      return 'Выдано';
    default:
      return status;
  }
}

function getStatusColor(status) {
  switch (status) {
    case 'Посылка принята от клиента':
      return 'neutral';
    case 'Посылка выдана':
      return 'success';
    case 'В пути':
      return 'neutral';
    default:
      return 'neutral';
  }
}

function getPaymentStatusColor(status) {
  switch (status) {
    case 'Не оплачено':
      return 'neutral';
    case 'Оплачено':
      return 'success';
    default:
      return status;
  }
}

function getDirection(from) {
  switch (from) {
    case 'Бали':
      return 'Бали – Москва';
    case 'Москва':
      return 'Москва – Бали';
    default:
      return from;
  }
}

export default viewModel;
