import styled from 'styled-components';
import { colors, sizes, fontSizes, radiuses, shadows, sizesRaw } from '../ui';

export const Wrapper = styled.div`
  .spinner {
    color: ${colors.neutral700};
    font-size: ${fontSizes.s3};
  }

  .packages {
    font-size: ${fontSizes.s3};

    table.packages {
      margin: ${sizes.s5} 0px 0px;

      td {
        padding: 2px ${sizes.s2};

        &:first-child {
          padding-left: 0px;
        }

        font-size: ${fontSizes.s3};

        &.caption {
          max-width: ${sizes.s11};
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &.right {
          text-align: right;
        }

        &.center {
          text-align: center;
        }
      }

      .img-container {
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }

        transition: opacity 300ms ease;
      }
    }

    main {
      overflow-x: scroll;
    }

    footer {
      margin-top: ${sizes.s4};
      display: flex;
      justify-content: flex-end;

      button {
        background: none;
        border: none;
        padding: 0;
        font-size: inherit;
        font-family: inherit;
        color: ${colors.neutral700};
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          color: ${colors.neutral900};
        }

        transition: color 150ms ease;
      }
    }
  }

  .photos-container {
    display: flex;
    column-gap: ${sizes.s2};
  }

  ul.orders {
    margin: 0px;
    padding: 0px;
    list-style-type: none;

    & > li {
      padding: ${sizes.s3} ${sizes.s4};
      box-shadow: ${shadows.s2};
      border-radius: ${radiuses.r3};
      background-color: #fff;

      &:not(.expanded) {
        &:hover,
        &:active {
          opacity: 0.7;
          cursor: pointer;
        }

        transition: opacity 300ms ease;
      }

      header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left-side {
          h3 {
            font-size: ${fontSizes.s4};
            margin: 0px;
          }

          .timestamp {
            color: ${colors.neutral600};
            font-size: ${fontSizes.s2};
          }
        }

        .right-side {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          row-gap: ${sizes.s2};

          .labels {
            display: flex;

            .label {
              &:not(:first-child) {
                margin-left: ${sizes.s1};
              }
            }
          }

          .circles {
            display: flex;

            .circle {
              height: ${sizes.s4};
              width: ${sizes.s4};
              border-radius: ${sizesRaw.s4 / 2}px;

              &:not(:first-child) {
                margin-left: ${sizes.s1};
              }

              &.circle-gray {
                background-color: ${colors.neutral300};
              }

              &.circle-green {
                background-color: ${colors.success600};
              }
            }
          }
        }
      }

      margin-bottom: ${sizes.s4};
    }
  }

  ul.packages {
    margin: ${sizes.s4} 0px 0px;
    padding: 0px;
    list-style-type: none;

    & > li {
      margin-bottom: 8px;
      font-size: ${fontSizes.s3};
    }
  }
`;
