import { createGlobalStyle } from 'styled-components';
import { colors, sizes, sizesRaw, fontSizes } from './ui';
import { radiuses } from './ui';

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 100%;
    height: 100%;
  }

  body {
    background-color: ${colors.neutral200};
    font-family: Inter;
    margin: 0px;
    margin: 0;
    color: ${colors.bodyText};
    line-height: 1.4;
    font-size: 100%;
    height: 100%;
  }

  table {
    border-collapse: collapse;
    font-feature-settings: 'tnum' 1;
  }

  * {
    box-sizing: border-box;
  }

  .m-l-s1 {
    margin-left: ${sizes.s1};
  }

  .m-l-s2 {
    margin-left: ${sizes.s2};
  }

  .m-l-s3 {
    margin-left: ${sizes.s3};
  }

  .label {
    height: ${sizesRaw.controlHeightSmall}px;
    border-radius: ${sizesRaw.controlHeightSmall / 2}px;
    font-size: ${fontSizes.s3};
    white-space: nowrap;
    padding: 0px ${sizes.s3};
    display: flex;
    justify-content: center;
    align-items: center;

    &.label-tiny {
      height: ${sizesRaw.controlHeightTiny}px;
      border-radius: ${sizesRaw.controlHeightTiny / 2}px;
      font-size: ${fontSizes.s2};
      padding: 0px ${sizes.s2};
    }

    &.label-primary {
      background-color: ${colors.primary100};
      color: ${colors.primary900};
    }

    &.label-danger {
      background-color: ${colors.danger100};
      color: ${colors.danger900};
    }

    &.label-neutral {
      background-color: ${colors.neutral300};
      color: ${colors.neutral900};
    }

    &.label-inverse {
      background-color: ${colors.neutral800};
      color: #fff;
    }

    &.label-success {
      background-color: ${colors.success100};
      color: ${colors.success900};
    }
  }

  button.button {
    border-style: none;
    padding: 0px ${sizes.s4};
    height: ${sizes.controlHeight};
    line-height: 1;
    font-size: ${fontSizes.s4};
    font-family: inherit;
    cursor: pointer;
    border-radius: ${radiuses.r2};
    transition: background-color 100ms ease;

    color: ${colors.bodyText};
    background-color: ${colors.neutral200};

    @media (hover: hover) {
      &:hover {
        background-color: ${colors.neutral300};
      }
    }

    &:active {
      background-color: ${colors.neutral300};
    }

    &.small {
      padding: 0px ${sizes.s3};
      height: ${sizes.controlHeightSmall};
      border-radius: ${radiuses.r2};
      font-size: ${fontSizes.s3};
    }

    &.link {
      background-color: transparent;
      color: ${colors.bodyText};

      @media (hover: hover) {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &.nav-link {
      background-color: transparent;
      padding-left: 0;
      font-size: 18px;

      &.active {
        color: ${colors.bodyText};
        pointer-events: none;
      }

      &:not(.active) {
        color: ${colors.neutral600};

        &:hover {
          color: ${colors.bodyText};
          text-decoration: underline;
        }
      }
    }

    &.text {
      background-color: transparent;
      color: ${colors.bodyText};

      &.active {
        background-color: ${colors.neutral300};
        font-weight: bold;
      }

      &:disabled {
        pointer-events: none;
        color: ${colors.neutral500}
      }

      @media (hover: hover) {
        &:hover:not(.active) {
          background-color: ${colors.neutral200};
        }
      }
    }

    &.large {
      padding: 0px ${sizes.s4};
      height: ${sizes.controlHeightLarge};
      border-radius: ${radiuses.r3};
      font-size: ${fontSizes.s6};
    }

    &.extra-large {
      padding: 0px ${sizes.s5};
      height: ${sizes.controlHeightExtraLarge};
      border-radius: ${radiuses.r3};
      font-size: ${fontSizes.s7};
    }

    &.secondary {
      color: ${colors.primary900};
      background-color: ${colors.primary100};

      @media (hover: hover) {
        &:hover {
          background-color: ${colors.primary200};
        }
      }

      &:active {
        background-color: ${colors.primary200};
      }
    }

    &.primary {
      color: #fff;
      background-color: ${colors.primary};

      @media (hover: hover) {
        &:hover {
          background-color: ${colors.primary400};
        }
      }

      &:active {
        background-color: ${colors.primary400};
      }

      :disabled {
        background-color: ${colors.primary300};
        pointer-events: none;
      }
    }

    &.danger {
      color: #fff;
      background-color: ${colors.danger};

      @media (hover: hover) {
        &:hover {
          background-color: ${colors.danger400};
        }
      }

      &:active {
        background-color: ${colors.danger400};
      }

      &.outline {
        color: ${colors.danger};
        background-color: #fff;
        border: 1px solid ${colors.danger};

        @media (hover: hover) {
          &:hover {
            background-color: ${colors.danger100};
          }
        }

        &:active {
          background-color: ${colors.danger100};
        }
      }

      &.secondary {
        background-color: ${colors.danger100};
        color: ${colors.danger900};

        @media (hover: hover) {
          &:hover {
            background-color: ${colors.danger200};
          }
        }

        &:active {
          background-color: ${colors.danger200};
        }
      }
    }
  }
`;

export default GlobalStyle;
