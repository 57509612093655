export default function Circles({ order }) {
  return (
    <div className="circles">
      {order.circles.map(({ isGreen }, index) => (
        <div
          key={index}
          className={`circle circle-${isGreen ? 'green' : 'gray'}`}
        />
      ))}
    </div>
  );
}
