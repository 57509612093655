import Photos from './Photos';

export default function Packages({ order, onShowLessClick }) {
  function handleShowLessClick() {
    if (onShowLessClick) {
      onShowLessClick();
    }
  }

  return (
    <div className="packages">
      <main>
        <table className="packages">
          <tbody>
            {order.packages.map(pkg => (
              <tr key={pkg.id}>
                <td>
                  <Photos title={pkg.title} photos={pkg.photos} />
                </td>
                <td className="caption">{pkg.title}</td>
                <td className="right">{pkg.price}</td>
                <td className="right">{pkg.weight}</td>
                <td className="center">
                  <span className={`label label-${pkg.statusColor}`}>
                    {pkg.status}
                  </span>
                </td>
                <td className="center">
                  <span className={`label label-${pkg.paymentStatusColor}`}>
                    {pkg.paymentStatus}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </main>
      <footer>
        <button onClick={handleShowLessClick}>Свернуть</button>
      </footer>
    </div>
  );
}
