import Carousel, { Modal, ModalGateway } from 'react-images';
import { useState } from 'react';

export default function Photos({ photos, title }) {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = index => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div className="photos-container">
      {photos.map((photo, index) => {
        return (
          <div
            key={index}
            className="img-container"
            onClick={() => openLightbox(index)}
          >
            <img src={photo.thumbnailUrl} alt={title} height="32px" />
          </div>
        );
      })}
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(({ url }) => ({ src: url, caption: title }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}
