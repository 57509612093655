// Base font size is 16px (s4)

export const fontSizes = {
  s1: '10px',
  s2: '12px',
  s3: '14px',
  s4: '16px',
  s5: '18px',
  s6: '20px',
  s7: '24px',
  s8: '30px',
  s9: '36px',
  s10: '48px',
  s11: '60px',
  s12: '72px'
};
